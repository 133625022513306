<template>
	  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-signup-on"
      
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #EBF6FFBF;"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <!--<a href="#" class="text-center mb-10">
            <img src="media/klik/logo.png" class="max-h-70px" alt=""/>
          </a>-->
           <div
				  class="d-flex flex-row-fluid bgi-no-repeat bgi-position-y-top bgi-position-x-center" style="background-size: 50%;height: 90px;"
				  :style="{ backgroundImage: `url(${LogoImage})` }"
			></div>
        </div>
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-top bgi-position-x-center" style="background-size: 100%;"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
			<div class="d-flex flex-row-fluid flex-column justify-content-center align-items-center text-center text-black ">
				<h1 class="display-3 font-weight-meduim">
					Votre compte a été désactivé
				</h1>
				<span class="display-4 font-weight-boldest mb-8">
					<a href="https://try.doken.co/" type="reset" class="btn btn-success mr-2" target="_blank">
						Abonnez-vous maintenant
					</a>
				</span>
			</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style  lang="scss">
	@import "@/assets/sass/pages/login/login-1.scss";
</style>
<script>
import { mapGetters } from "vuex";
export default {
	name: "Error-2",
	data: () => ({
		HasVerif: false,
		HasVerifCodeInput: false,
		values: [],
	}),
	mounted() {
		
	},//Impossible de valider le numéro de téléphone
	computed: {
		backgroundImage() {
		  return process.env.BASE_URL + "media/klik/b-1.png";
		},
		LogoImage() {
		  return process.env.BASE_URL + "media/klik/logo.png";
		},
		...mapGetters(["currentUserPersonalInfo"]),
		...mapGetters(["StatusPayActiveShop"]),
	},
	methods: {
		
	},
}; 
</script>
